/* Styling for all generic Releases */

.coverArt {
    /* padding: 20px 20px 20px 20px; */
    margin: 5px 0;
    display: inline-block;
    width: 10em;
    height: auto;
    background-color: white;
    border: solid 2px white;
}

.releaseInfo {
    text-align: center;
    margin: 15px 0;
}

.releaseHeader {
    padding: 0;
}

.releaseBio {
    font-size: 12px;
}

.releaseLinks {
    margin: 10px 0;
}

.releaseEmbed {
    /* max-width: 80%; */
    max-height: 70vh;
    border: solid 5px white;
    /* margin: 20px 0;  */
}