.about {
    text-align: unset;
    font-family: monospace;
    height: 100vh;
    padding-top: 1vh;
}

.about-body {
    font-family: monospace;
    padding: 5vw;
    padding-top: 7vw;
    font-size: 16px;
}

#aboutRow {
    text-align: left;
}

#aboutLogo {
    text-align: unset;
}

#aboutSignOff {
    padding: 20px 20px 20px 20px;
    margin: 175px 0 0 0;
    display: inline-block;
    width: 75px;
    height: auto;
}