.gallery {
    text-align: unset;
    font-family: monospace;
    height: 100vh;
    padding-top: 1.5vh;
}

/* @media screen and (max-width: 992px) {
    .gallery h3 {
        font-size: 1.5rem;
    }
} */

.gallery-body {
    text-align: unset;
    font-family: monospace;
    padding: 5vw;
    padding-top: 7vw;
    padding-bottom: 100px;
}

.galleryImage {
    padding: 20px;
    margin: 5px 0;
    display: inline-block;
    border: solid black 1px;
}

/** MEDIA QUERIES *************************************************/

/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 568px) { 
    .gallery-body {
        text-align: unset;
        font-family: monospace;
        padding: 5vw;
        padding-top: 7vw;
        padding-bottom: 100px;
    }
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {  
    .gallery-body {
        text-align: unset;
        font-family: monospace;
        padding: 5vw;
        padding-top: 7vw;
        padding-bottom: 100px;
    }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) { 
    .gallery-body {
        text-align: unset;
        font-family: monospace;
        padding: 5vw;
        padding-top: 7vw;
        padding-bottom: 100px;
    }
 }

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
    .gallery-body {
        text-align: unset;
        font-family: monospace;
        padding: 5vw;
        padding-top: 7vw;
        padding-bottom: 150px;
    }
}