.profile {
    text-align: unset;
    font-family: monospace;
    /* height: 100vh; */
    padding-top: 1vh;
    margin-bottom: 100px;
    /* padding-left: 15px; */
    /* padding-right: 15px; */
}

.profileMember {
    text-align: unset;
    font-family: monospace; 
}


.profileBody {
    text-align: unset;
    font-family: monospace;
    font-size: 16px;
    padding-bottom: 25px;
}

.pictureRow {
    padding-bottom: 10px;
}

/** Profile Header */
.profileImage {
    margin: 5px 0;
    display: inline-block;
}

.proPicCrop {
    margin: 5px 0;
    width: 50%;
    height: auto;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.proPic {
    margin: auto;
}

.galleryImage {
    padding: 20px;
    margin: 5px 0;
    display: inline-block;
    border: solid black 1px;
}

.linkRow {
    text-align: center;
    display: block;
    margin-bottom: 10px;
    padding: 10px 0;
}

.profileCol {
    min-height: 100px;
    margin: 10px 0;
}

.profileCol a {
    text-decoration: none;
    color: black;
}

.profileRow {
    /* height: 80vh;
    max-height: 80vh; */
}

#bottomProfileRow {
    margin-bottom: 350px;
}

/* Logo */
#dcwwLogo {
    text-align: unset;
    /* padding: 20px 20px 20px 20px; */
    margin: 5px 0;
    display: inline-block;
    width: 75px;
    height: auto;
}

#aboutSignOff {
    padding: 20px 20px 20px 20px;
    margin: 175px 0 0 0;
    display: inline-block;
    width: 75px;
    height: auto;
}





/* NEW IMPLEMENTATION WITHOUT "PROFILECARD" */
/* 1/10/21 */

.profileCardDeck {
    /* padding: 0 5vh; */
    margin-bottom: 100px;
}

/* Profile Card (Generic) */
.profileCard {
    /* min-width: 18rem; */
    margin-bottom: 15px;
    font-size: calc(8px + 1vmin);
}

.profileCard h5 {
    font-size: 0.9rem;
    margin-bottom: 0;
    text-decoration: none;
}

.memberProfileCard {
    margin-bottom: 15px;
    font-size: calc(8px + 1vmin);
    text-decoration: none;
    color: black;
}

.memberProfileCard :hover {
    text-decoration: none;
    color: black;
}

.memberProfileCardTitle {
    font-weight: bold;
}

.buttonCard {
    box-shadow: 5px 5px 0px 0px #8E8D8D;
}

/* Put this in Media QUERY */
/* .buttonCard:hover {
    background-color: lightgray;
} */

.buttonCard:active {
    background-color: black;
    color: white;
    box-shadow: 2px 2px 3px 0px #8E8D8D;
    transform: translate(2px);
}

/* Image Card */
.imageCardText {
    padding: 1rem 0.75rem;
    text-decoration: none;
}

#imageCardLM {
    background-color: #231f20;
}

.linkCard {
    color: black;
    text-decoration: none;
}


/** Profile-specific *************************************************/
#stoicdapoet {
    /* background: purple; */
}

.stoicBio {
    background-color: lightgray;
    padding: 20px;
    font-size: 12px;
    height: inherit;
}

#stoicOverlayHeader {
    display: block;
    text-align: center;
    color: black;
}

.stoicOverlayButton {
    text-decoration: none;
}

.stoicModal {
    font-family: monospace;
}



/** MEDIA QUERIES *************************************************/

/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 568px) { 
    .profileCard {
        /* min-width: 18rem; */
        margin-bottom: 15px;
    }

    #profileCardTop {
        /* min-width: 18rem; */
        margin-bottom: 15px;
        text-decoration: none;
        color: black;
    }

    #profileCardTop :hover {
        text-decoration: none;
        color: black;
    }

    .memberProfileCard {
        display: block;
        margin: auto;
    }
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {  

    .profileRow {
        height: 30vh;
    }

    #profileCardTop {
        /* min-width: 15rem; */
        margin-bottom: 15px;
        text-decoration: none;
        color: black;
    }

    .profileCard {
        /* min-width: 15rem; */
        margin-bottom: 15px;
        text-decoration: none;
        color: black;
    }

    .memberProfileCard {
        width: 20vw;
        display: block;
        margin: auto;
    }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) { 
    .proPicCrop {
        margin: 5px 0;
        width: 25%;
        height: auto;
        border-radius: 50%;
        position: relative;
        overflow: hidden;
        display: inline-block;
    }
    
    .proPic {
        margin: auto;
    }

    .profileRow {
        height: 35vh;
    }

    #profileCardTop {
        min-width: 0;
        margin-bottom: 15px;
        text-decoration: none;
        color: black;
    }

    .profileCard {
        min-width: 0;
        margin-bottom: 15px;
        text-decoration: none;
        color: black;
    }
    
 }

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
    #profileCardTop {
        min-width: 0;
        margin-bottom: 15px;
        text-decoration: none;
        color: black;
    }

    .profileCard {
        min-width: 0;
        margin-bottom: 15px;
        text-decoration: none;
        color: black;
    }
 }