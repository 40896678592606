.worldwide {
    text-align: unset;
    font-family: monospace;
    font-size: calc(9px + 1vmin);
}

.worldwide h4 {
    font-variant: small-caps;
    font-weight: bold;
    text-align: center;
    font-size: calc(30px + 1vmin);
}

.worldwide ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: center;
}

.worldwideHdr {
    background-color: black;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60vmin;
    z-index: 999;
    transition: height 0.3s;
}

.worldwideHdr.smaller {
    height: 20vmin;
}

/***** Symbol/Logo Styles *****/
.logo {
    height: 60vmin;
    -webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-ms-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
}

.logo.smaller {
    height: 20vmin;
}

.columnDelim {
    display: inline-block;
    text-align: center;
    width: 100%;
    padding-bottom: 5px;
}

.columnBlackSmall {
    height: 2vh;
    padding: 0 10px;
}

.dropletSpacer {
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
}

.dropletBlackSmall {
    height: 2vh;
    padding: 0 10px;
}
/***** END: Symbol/Logo *****/

.worldwideInfo {
    position: absolute;
    top: 60vmin;
    left: 0;
    padding-bottom: calc(9px + 2rem);
}

.worldwideMotto {
    padding: 10px 10px;
}

#dcwwTitle {
    font-size: 1.5rem;
    text-align: center;
}

.worldwideIcons {
    color: black;
    font-size: 1.35em;
    padding: 18px 18px;
    margin: 5px;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    appearance: unset;
}

#worldwideBio {
    padding: 20px 0 10px 0;
}

.worldwideListen {
    padding: 10px;
}

.worldwidePlaylist {
    border: solid 5px black;
    margin: 20px 0; 
}

/** Philosophy */

.worldwidePhilosophy {
    width: 100%;
    z-index: 0;
    text-align: center;
    padding: 10px 0;
}

.worldwidePhilosophy ul {
    padding: 0 50px;
    text-align: center;
}

.worldwidePhilosophy li {
    margin: 10px 0;
}

.philosophyInfo {
    padding: 0 30px;
}

.philosophyPillars {
    margin-top: 10px;
    padding: 0 10px;
}

/** Roster */

.worldwideRoster {
    width: 100%;
    z-index: 0;
    margin-bottom: 5rem;
}

.worldwideRoster li {
    padding: 5px;
    text-decoration: none;
}

.worldwideRoster a {
    color: black;
    text-decoration: none;
}

.worldwideRoster h5 {
    margin: 15px 0;
    font-variant: small-caps;
    /* font-size: calc(15px + 1vmin); */
}

#worldwideRosterArtistsHdr {
    margin: 25px 0;
}

.rosterPicCrop {
    margin: 5px 0;
}

.rosterPic {
    margin: auto;
    height: 15vh;
    transition: height 0.1s linear;
}

.rosterPicCrop :hover {
    height: 16vh;
}

.divider {
    padding: 0 30px;
}

.divider hr {
    margin: 0;
}

/** Specific IDs **/
#pillars {
    font-variant: small-caps;
    font-size: calc(12px + 1vmin);
    font-weight: bold;
}


/** MEDIA QUERIES *************************************************/
/** NOTE: Media Queries */

/* Extra small devices (small phones) */
@media (max-width: 320px) {
    #dcwwTitle {
        font-size: 1.45rem;
        text-align: center;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 568px) { 
    .columnBlackSmall {
        height: 4vh;
        padding: 0 10px;
    }

    .dropletBlackSmall {
        height: 3vh;
        padding: 0 10px;
    }

    .worldwidePlaylist {
        border: solid 5px black;
        margin: 20px 50px; 
    }

    .worldwidePhilosophy {
        width: 100%;
        z-index: 0;
        text-align: center;
        padding: 10px 0;
    }

    .philosophyInfo {
        padding: 0 70px;
    }
    
    .worldwideRoster {
        width: 100%;
        z-index: 0;
        margin-bottom: 5rem;
    }
}
  
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
    .worldwideHdr {
        background-color: black;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 50vmin;
        z-index: 999;
        transition: height 0.3s;
    }
    
    .worldwideHdr.smaller {
        height: 20vmin;
    }

    .logo {
        height: 50vmin;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
    }
    
    .logo.smaller {
        height: 20vmin;
    }

    .worldwideInfo {
        position: absolute;
        top: 50vmin;
        left: 0;
        padding-bottom: calc(9px + 2rem);
    }

    .worldwideMotto {
        padding: 10px 10px;
    }
    
    #dcwwTitle {
        font-size: 2.25rem;
        text-align: center;
    }

    .worldwidePlaylist {
        border: solid 5px black;
        margin: 20px 80px; 
    }

    .worldwidePhilosophy ul {
        padding: 0 50px;
        text-align: center;
    }

    .worldwidePhilosophy {
        width: 100%;
        z-index: 0;
        text-align: center;
        padding: 10px 0;
    }

    .philosophyInfo {
        padding: 0 70px;
    }

    .philosophyPillars {
        margin-top: 10px;
        padding: 0 50px;
    }
    
    .worldwideRoster {
        width: 100%;
        z-index: 0;
        margin-bottom: 5rem;
    }

    .columnBlackSmall {
        height: 2.5vh;
        padding: 0 10px;
    }
    
    .dropletBlackSmall {
        height: 2vh;
        padding: 0 10px;
    }
}
  
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
    .worldwideHdr {
        background-color: black;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 30vmin;
        z-index: 999;
    }

    .worldwideHdr.smaller {
        background-color: black;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 10vmin;
        z-index: 999;
    }
    
    .logo {
        height: 30vmin;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
    }
    
    .logo.smaller {
        height: 10vmin;
    }

    .columnBlackSmall {
        height: 2.5vh;
        padding: 0 10px;
    }

    .dropletBlackSmall {
        height: 2vh;
        padding: 0 10px;
    }

    .worldwidePlaylist {
        border: solid 5px black;
        margin: 20px 15vw; 
    }

    .worldwideInfo {
        position: absolute;
        top: 30vmin;
        left: 0;
        height: 75%;
    }

    .worldwidePhilosophy {
        width: 100%;
        z-index: 0;
        text-align: center;
        padding: 10px 150px;
    }
    
    .worldwidePhilosophy ul {
        padding: 0 50px;
        text-align: center;
    }

    .philosophyPillars {
        margin-top: 10px;
        padding: 0 50px;
    }

    .worldwideRoster {
        width: 100%;
        z-index: 0;
        padding: 20px 30px 50px 30px;
        margin-bottom: 5rem;
    }
}
  
/* Extra Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
    .worldwidePhilosophy {
        width: 100%;
        z-index: 0;
        text-align: center;
        padding: 10px 150px;
    }

    .philosophyInfo {
        padding: 0 120px;
    }

    .philosophyPillars {
        margin-top: 10px;
        padding: 0 50px;
    }
    
    .worldwideRoster {
        width: 100%;
        z-index: 0;
        margin-bottom: 5rem;
    }
}