html, body {
  height: 100%;
} 

.Home {
  text-align: center;
}

.Home22 {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: monospace;
  font-size: smaller;
  color: white;
  background: url("../images/dcww_website22.png") no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.Artists {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 1rem 0;
  text-align: center;
  font-family: monospace;
  font-size: smaller;
  color: white;
  background: url("../images/artist_bkg.jpg") no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.releaseContainer {
  position: absolute;
  width: 100%;
  padding: 1rem 0;
  text-align: center;
  font-family: monospace;
  font-size: smaller;
  /* background: url("../images/tectonic/tec_bkgdark.jpg") no-repeat center center fixed;  */
  background: url("../images/tectonic/tec_bkgdark.jpg");
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  min-height: calc(100vh - 104px);
}

.releaseCover {
  height: 50vmin;
  width: 50vmin;
  border-radius: 5px;
}

#Stoic {
  position: absolute;
  width: 100%;
  padding: 1rem 0;
  text-align: center;
  font-family: monospace;
  font-size: smaller;
  /* background: url("../images/tectonic/tec_bkgdark.jpg") no-repeat center center fixed; */
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  min-height: 100vh;
  flex-direction: column;
  align-content: center;
  align-items: center;
}


/* Pre '22 Revamp */

.Home-dark {
  text-align: center;
  background-color: black;
}

.logo {
  height: 40vmin;
}

.icons {
  padding: 15px;
}

.App-header {
  font-family: monospace;
  display: flex;
  padding: 10px;
}

.App-body {
  min-height: 95vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  font-size: calc(10px);
  padding: 0;
}

.main-body {
  align-items: center;
}

.App-footer {
  margin: 5px;
  font-family: monospace;
  font-size: small;
}

.stickyFooter {
  text-align: center;
  display: block;
  background: white;
  opacity: 0.95;
  z-index: 1;
}

.socialButton {
  color: white;
  /* background-color: white; */
  border: none;
  border-radius: 30px;
  padding: 50px;
  font-size: 3.5em;
  cursor: pointer;
  appearance: unset;
}

#spotifyFollowBtn {
  display: none;
  border: none;
  overflow: hidden;
  margin-top: 15px;
  margin-right: -80px;
}

.Home-body {
  flex: 1;
  z-index: 1;
}

/* Nav & Sidebar CSS */

.navButton {
  float: left;
  padding: 10px;
  font-size: 0.75em;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;         /* This keeps it above the sidebar */
}

.navBtnInverted {
  color: white;
}

#nav-btn {
  cursor: pointer;
  transform: rotate(0deg);
  transition: transform 0.25s linear;
}

#nav-btn-open {
  cursor: pointer;
  transform: rotate(90deg);
  transition: transform 0.25s linear;
}

.profileIconButtons {
  z-index: 0;
  position: fixed;
  left: 50%;
  bottom: 20px;
  transform: translate(-50%, -50%);
  margin: 0 auto;
}

.homeProfileButton {
  width: 30px;
  height: 30px;
  margin: 0 25px 10px 25px;
}



/** MEDIA QUERIES *************************************************/

/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) { 
  .homeProfileButton {
    width: 40px;
    height: 40px;
    margin: 0 25px 10px 25px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 

  .homeProfileButton {
    width: 30px;
    height: 30px;
    margin: 0 25px 10px 25px;
  }

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 

  .homeProfileButton {
    width: 35px;
    height: 35px;
    margin: 0 25px 10px 25px;
  }

}

/* Extra Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
  
  .homeProfileButton {
    width: 50px;
    height: 50px;
    margin: 0 25px 10px 25px;
  }

}


/* Old styles */

.styledSideNav {
  padding: 0;
  font-size: calc(10px + 1vmin);
}

.sidebarLinks {
  list-style: none;
  padding: 25px 15px;
  font-stretch: expanded;
  /* font-size: 3vmin; */
}

.sidebarLinks li {
  padding: 10px 0;
}

.sidebarLinks a {
  color: black;
  text-decoration: none;
}

#chapterSynopsisNav {
  font-size: calc(10px + 0.5vmin);
}



.side-nav-on {
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  background-color: #111; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
}

.side-nav-on ul {
  list-style: none;
}
